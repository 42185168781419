export default class PreventDouble {
  constructor(el) {
    this.el = el
    this.submitButton = this.el.querySelector("button")

    this.el.addEventListener("submit", (_e) => {
      this.disableForm()
      setTimeout(() => this.enableForm(), 3000)
    })
  }

  disableForm() {
    this.submitButton.disabled = true
    this.submitButton.innerText = "Bitte warten..."
  }

  enableForm() {
    this.submitButton.disabled = false
    this.submitButton.innerText = "Abschicken"
  }
}
