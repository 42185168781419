/* global $$:false, $:false */

import Subscription from "./subscription.js"

class SubscriptionList {
  constructor(list) {
    this.el = list
    this.items = []
    this.csrf = $("input[name=csrfmiddlewaretoken]", this.el).value

    this.subscription = new Subscription(this.csrf)

    this.init()
  }

  init() {
    $$("[data-subscription-list-item]", this.el).forEach((item) => {
      this.items.push(new SubscriptionListItem(item, this.subscription))
    })
  }
}

class SubscriptionListItem {
  constructor(el, subscription) {
    this.el = el
    this.actionURL = this.el.dataset.subscriptionListItem
    this.button = $("[data-subscription-list-item-button]", this.el)
    this.message = $("[data-subscription-list-item-message]", this.el)

    this.subscription = subscription

    this.unsubscribeClass = "thread-list__item--unsubscribed"

    this.addListeners()
  }

  addListeners() {
    this.button.addEventListener("click", (e) => {
      e.preventDefault()
      this.unsubscribe()
    })
  }

  unsubscribe() {
    this.subscription.unsubscribe(this.actionURL, () => this.hide())
  }

  hide() {
    this.button.setAttribute("aria-hidden", true)
    this.message.setAttribute("aria-hidden", false)

    this.el.classList.add(this.unsubscribeClass)
  }
}

export default SubscriptionList
