/* global $: false */
import Subscription from "./subscription.js"

class SubscriptionForm {
  constructor(form) {
    this.form = form
    this.checkbox = $("input[type=checkbox]", this.form)
    this.csrf = $("input[name=csrfmiddlewaretoken]", this.form)

    this.isActive = this.checkbox.checked

    this.subscription = new Subscription(this.csrf.value)

    this.addListeners()
  }

  addListeners() {
    this.checkbox.addEventListener("change", (e) => {
      e.preventDefault()
      this.toggleSubscription()
    })
  }

  toggleSubscription() {
    if (this.isActive) {
      this.subscription.unsubscribe(
        this.form.action,
        () => this.setActive(),
        () => this.resetCheckbox(),
      )
    } else {
      this.subscription.subscribe(
        this.form.action,
        () => this.setActive(),
        () => this.resetCheckbox(),
      )
    }
  }

  setActive() {
    this.isActive = this.checkbox.checked
  }

  resetCheckbox() {
    this.checkbox.checked = this.isActive
  }
}

export default SubscriptionForm
