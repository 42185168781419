/* global $: false */
// Controlling mobile navigation and aria attributes

class Navigation {
  constructor() {
    this.header = $("#header")
    this.navContainer = $(".nav-container", this.header)
    this.navToggle = $(".nav-toggle", this.header)
    this.navQuickLink = $(".nav-skip", this.header)

    this.moodboard = $("#moodboard")
    this.maincontent = $("#maincontent")
    this.footer = $("#footer")

    this.firstFocusableElement = this.navToggle
    this.lastFocusableElement = $("li:last-child a", this.navContainer)

    // medium foundation breakpoint
    this.breakpoint = matchMedia("only screen and (min-width: 1024px)")

    this.isOpen = false
    this.setListeners()
    this.handleBreakpointChange(this.breakpoint)

    // event handlers
    this._shiftTabListener = (e) => this.shiftTabListener(e)
    this._tabListener = (e) => this.tabListener(e)
    this._escapeListener = (e) => this.escapeListener(e)
  }

  setListeners() {
    this.navToggle.addEventListener("click", () => this.toggle())

    this.navQuickLink.addEventListener("click", (e) => {
      if (!this.breakpoint.matches) {
        e.preventDefault()
        this.toggle()
      }
    })

    // Listens to a breakpoint change
    this.breakpoint.addListener((mql) => this.handleBreakpointChange(mql))
  }

  tabListener(event) {
    // only tab
    if (!event.shiftKey && event.keyCode === 9) {
      event.preventDefault()
      this.firstFocusableElement.focus()
    }
  }

  shiftTabListener(event) {
    // shift & tab
    if (event.shiftKey && event.keyCode === 9) {
      event.preventDefault()
      this.lastFocusableElement.focus()
    }
  }

  escapeListener(event) {
    // escape
    if (event.keyCode === 27) {
      event.preventDefault()
      this.close()
    }
  }

  /*
   *   Capture the focus inside the overlay
   *   'Redirect' a 'tab' on the last element to the first element.
   *   'Redirect' a 'shift' + 'tab' on the last element to the first element again.
   *   This prevents that the focus will get lost outside of the overlay unless it will be closed.
   */

  captureFocus() {
    this.firstFocusableElement.addEventListener(
      "keydown",
      this._shiftTabListener,
    )
    this.lastFocusableElement.addEventListener("keydown", this._tabListener)
    this.navToggle.focus()
  }

  /*
   *   Remove the listeners again. Make the rest of the page accessible for focus/tab again.
   */

  relaseFocus() {
    this.firstFocusableElement.removeEventListener(
      "keydown",
      this._shiftTabListener,
    )
    this.lastFocusableElement.removeEventListener("keydown", this._tabListener)
    this.navToggle.focus()
  }

  /*
   *   This method sets the aria attributes according to the state (open/closed)
   *   of the navigation and the current breakpoint.
   *   If the window is wider than the medium breakpoint, the navigation will have
   *   the same attributes as an open navigation in a small breakpoint.
   */

  handleBreakpointChange(mql) {
    if (mql.matches) {
      if (this.isOpen) {
        this.close()
      }

      this.resetAriaAttributes()
    } else {
      this.setAriaAttributes(this.isOpen)
    }
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  /*
   * Open mobile navigation
   */

  open() {
    document.body.classList.add("open-nav")
    this.setAriaAttributes(true)
    this.captureFocus()
    document.addEventListener("keydown", this._escapeListener)
    this.isOpen = true
  }

  /*
   * Close mobile navigation
   */

  close() {
    document.body.classList.remove("open-nav")
    this.setAriaAttributes(false)
    this.relaseFocus()
    document.removeEventListener("keydown", this._escapeListener)
    this.isOpen = false
  }

  /*
   * Set Aria attributes
   */

  setAriaAttributes(navIsOpen) {
    let mqlMatches = this.breakpoint.matches

    // the navContainer is marked as hidden when the navigation is not open
    this.navContainer.setAttribute("aria-hidden", !navIsOpen)

    // the following elements are marked as hidden when the navigation is open.
    this.moodboard.setAttribute("aria-hidden", navIsOpen)
    this.maincontent.setAttribute("aria-hidden", navIsOpen)
    this.footer.setAttribute("aria-hidden", navIsOpen)

    // the navToggle is marked as hidden when the breakpoint is medium or higher
    this.navToggle.setAttribute("aria-hidden", mqlMatches)
    this.navToggle.setAttribute("aria-expanded", navIsOpen)
  }

  /*
   * Reset the aria attributes to the state when the mobile navigation is not used.
   */

  resetAriaAttributes() {
    this.navContainer.setAttribute("aria-hidden", false)

    this.moodboard.setAttribute("aria-hidden", false)
    this.maincontent.setAttribute("aria-hidden", false)
    this.footer.setAttribute("aria-hidden", false)

    this.navToggle.setAttribute("aria-hidden", true)
    this.navToggle.setAttribute("aria-expanded", false)
  }
}

export default Navigation
