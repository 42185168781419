/* global $: false */

const DAY = 24 * 60 * 60 * 1000
const WEEK = DAY * 7

function getWeekdays() {
  return (
    window.WEEKDAYS_TRANSLATION || [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ]
  )
}

// Media queries
const MEDIUM_UP = window.matchMedia("screen and (min-width: 640px)")

class Messenger {
  constructor(el) {
    this.el = el
    this.conversations = {}
    this.tabList = $(".conversation-tab-list", this.el)

    this.conversationForm = new ConversationForm()

    this.active = {
      tab: null,
      conversation: null,
    }

    this.friendsUrl = this.el.dataset.friendsUrl
    this.friends = []

    this.addListeners()
    this.fetchFriends()
  }

  // Show active or first conversation
  init() {
    let activeTab = null

    /*
     *   show the requested conversation if it already exists.
     *   otherwise show the 'add conversation' form with the preselected user
     */
    if (this.el.dataset.currentConversation) {
      let currentConversation = this.el.dataset.currentConversation

      if (this.tabList !== null) {
        activeTab = $(`[data-id="${currentConversation}"]`, this.tabList)
      }

      if (activeTab === null) {
        if (this.conversationForm.selectUser(currentConversation)) {
          this.conversationForm.toggle(true)
        }
      }
    }

    if (MEDIUM_UP.matches && this.tabList != null) {
      if (activeTab === null) {
        // select the first (most recent) conversation
        activeTab = $("a", this.tabList)
      }

      this.toggleTab(activeTab)
    } else if (this.tabList === null) {
      this.el.classList.add("empty")
    } else if (activeTab) {
      this.toggleTab(activeTab)
    }
  }

  addListeners() {
    $.delegate(this.el, "click", ".conversation-tab-list a", (e) => {
      e.preventDefault()
      let target = e.target

      while (target.nodeName !== "A") {
        target = target.parentNode
      }

      this.toggleTab(target)
    })

    /*
     * Closes the current conversation.
     * Only on 'small' screens.
     */
    $.delegate(this.el, "click", ".conversation [data-back-button]", (e) => {
      e.preventDefault()
      this.hideCurrentConversation()
    })
  }

  toggleTab(tab) {
    // A conversion on 'medium up' screens can not be closed
    if (tab !== this.active.tab || !MEDIUM_UP.matches) {
      tab.setAttribute("aria-selected", "true")
      tab.classList.add("read")

      if (this.active.tab !== null) {
        this.active.tab.setAttribute("aria-selected", "false")
      }

      this.active.tab = tab
      this.showConversation(tab)
    } else if (tab === this.active.tab) {
      this.active.conversation.setFocus()
    }
  }

  // prettier-ignore
  showConversation(tab) {
    let id = parseInt(tab.dataset.id, 10)
    let elementSelector = tab.hash

    let conversation = this.conversations[id]

    if(conversation === undefined) {
      let isFriend = this.getFriendState(id)

      this.conversations[id] = new Conversation(
        $(elementSelector, this.el),
        id,
        tab,
        isFriend
      )
      conversation = this.conversations[id]
    }

    conversation.show()

    if(this.active.conversation !== null) {
      this.active.conversation.hide()
    }

    this.active.conversation = conversation
  }

  /*
   * This method closes the current conversation
   * This is only used on 'small' screens
   */
  hideCurrentConversation() {
    this.active.tab.setAttribute("aria-selected", "false")
    this.active.tab = null

    this.active.conversation.hide()
    this.active.conversation = null
  }

  /*
   * Fetch all friends. The data will be used to disable conversations
   * with users which are not friends anymore. The conversation will remain
   * as readable.
   */
  fetchFriends() {
    $.fetch(this.friendsUrl, {
      method: "GET",
      responseType: "json",
    }).then((xhr) => {
      let response = parseJSON(xhr)
      this.friends = response.friends

      this.init()
    })
  }

  /*
   * Check if user is still a friend or not
   * returns true if it is still a friend
   */
  getFriendState(id) {
    let isFriend = false

    this.friends.forEach((friend) => {
      if (friend.id === id) {
        isFriend = true
      }
    })

    return isFriend
  }
}

class Conversation {
  constructor(el, id, tab, isFriend) {
    this.el = el
    this.tab = tab
    this.messageList = $("[data-message-list]", this.el)
    this.textarea = $("[data-textarea]", this.el)
    this.csrftoken = $("input[name=csrfmiddlewaretoken]", this.el)
    this.sendButton = $("[data-send-button]", this.el)
    this.footer = this.textarea.closest(".conversation__footer")
    this.nextPageButton = $("[data-next-page-button]", this.el)
    this.scrollingArea = $("[data-scrolling-area]", this.el)

    this.id = id
    this.fetchUrl = this.el.dataset.fetchUrl
    this.sendUrl = this.el.dataset.sendUrl

    this.messages = []
    this.nextPage = null
    this.currentPage = this.fetchUrl
    this.active = false
    this.pollingInterval = null

    this.writeable = isFriend

    this.fetch()

    if (this.writeable) {
      this.addListeners()
    } else {
      this.disableWrite()
    }
  }

  addListeners() {
    /* Auto expand textarea */
    this.textarea.addEventListener("keyup", (e) => {
      this.setTextareaHeight()

      if (e.target.value.trim() !== "") {
        this.sendButton.disabled = false
      } else {
        this.sendButton.disabled = true
      }
    })

    this.textarea.addEventListener("keydown", (e) => {
      // shift & tab
      if (e.shiftKey && e.keyCode === 9) {
        e.preventDefault()
        this.tab.focus()
      }
    })

    this.sendButton.addEventListener("click", (e) => {
      e.preventDefault()

      if (this.textarea.value.trim() !== "" && !e.target.disabled) {
        this.send()
      }
    })

    this.nextPageButton.addEventListener("click", (e) => {
      e.preventDefault()
      this.fetchNextPage()
    })
  }

  fetch() {
    $.fetch(this.fetchUrl, {
      method: "GET",
      responseType: "json",
    })
      .then((xhr) => {
        let response = this.parseJSON(xhr)

        // the messages are empty before the first fetch
        if (this.messages.length >= 1) {
          this.compareMessages(response.messages)
        } else {
          this.messages = response.messages
          this.showAllMessages()

          // only set nextPage on the first fetch. after that it should be controlled by fetchNextPage
          this.setNextPage(response.next_page)
        }
      })
      .catch(() => {
        this.prependError()
      })
  }

  fetchNextPage() {
    this.deactivatePolling()

    $.fetch(this.nextPage, {
      method: "GET",
      responseType: "json",
    })
      .then((xhr) => {
        let response = this.parseJSON(xhr)

        this.appendNextPage(response.messages)
        this.currentPage = this.nextPage
        this.setNextPage(response.next_page)
        this.activatePolling()
      })
      .catch(() => {
        this.appendError()
      })
  }

  send() {
    $.fetch(this.sendUrl, {
      method: "POST",
      data: `${this.urlEncode("receiver", this.id)}&${this.urlEncode(
        "text",
        this.textarea.value,
      )}`,
      headers: {
        "X-CSRFToken": this.csrftoken.value,
      },
    })
      .then(() => {
        this.fetch()
        this.textarea.value = ""
        this.setTextareaHeight()
        this.setFocus()
        this.sendButton.disabled = true
      })
      .catch(() => {
        this.prependError()
      })
  }

  // for browsers that don't support responseType = 'json'
  parseJSON(xhr) {
    let response = null

    if (typeof xhr.response === "string" && xhr.responseType !== "json") {
      response = JSON.parse(xhr.response)
    } else {
      response = xhr.response
    }

    return response
  }

  show() {
    this.el.setAttribute("aria-hidden", false)

    if (this.writeable) {
      this.activatePolling()
      this.setFocus()
    }

    this.active = true
  }

  hide() {
    this.el.setAttribute("aria-hidden", true)

    if (this.writeable) {
      this.deactivatePolling()
    }

    this.active = false
  }

  activatePolling() {
    this.pollingInterval = setInterval(() => {
      this.fetch()
    }, 10000)
  }

  deactivatePolling() {
    clearInterval(this.pollingInterval)
  }

  createErrorElement(errorMessage) {
    let errorElement = document.createElement("li")
    errorElement.className = "info"

    errorMessage =
      errorMessage || "Es ist ein Fehler aufgetreten. Bitte lade die Seite neu."

    errorElement.innerHTML = `<p class="info__content">${errorMessage}</p>`

    return errorElement
  }

  appendError(errorMessage) {
    this.deactivatePolling()
    this.messageList.appendChild(this.createErrorElement(errorMessage))
  }

  prependError(errorMessage) {
    this.deactivatePolling()
    this.messageList.insertBefore(
      this.createErrorElement(errorMessage),
      this.messageList.firstElementChild,
    )
  }

  // in case both users are not friends anymore
  // the conversation will still be readable
  disableWrite() {
    this.footer.classList.add("disable")
  }

  /*
   * This method compares the newly fetched messages to the messages which are already shown inside the conversation
   * Every message that follows after the last message inside the conversation will be prepended to the conversation.
   */
  compareMessages(fetchedMessages) {
    let latestMessage = this.messages[0]
    let position = 0
    let newMessages = []

    // find the last message of the conversation inside the fetched messages
    fetchedMessages.forEach((message) => {
      if (
        latestMessage.created_at === message.created_at &&
        latestMessage.text === message.text &&
        latestMessage.role === message.role
      ) {
        position = fetchedMessages.indexOf(message)
      }
    })

    // there are no new messages if the position is 0
    if (position > 0) {
      newMessages = fetchedMessages.slice(0, position)

      // start from the end of the array
      for (let i = newMessages.length - 1; i >= 0; i--) {
        this.prependMessage(newMessages[i])
        this.messages.unshift(newMessages[i])
      }

      this.scrollToBottom()
    } else {
      this.messages = fetchedMessages
    }
  }

  showAllMessages() {
    for (let i = this.messages.length - 1; i >= 0; i--) {
      this.prependMessage(this.messages[i])
    }

    this.scrollToBottom(true)
  }

  createMessage(messageData) {
    let message = document.createElement("li")
    let createdAt = this.dateFormat(messageData.created_at)

    message.className = `message message--${messageData.role}`
    message.innerHTML =
      `<span class="visuallyhidden">${messageData.role}:</span>` +
      `<div class="message__content"><p>${messageData.text}</p>` +
      `<time class="message__time">${createdAt}</time>` +
      `</div>`

    return message
  }

  appendMessage(messageData) {
    let message = this.createMessage(messageData)
    this.messageList.appendChild(message)
  }

  prependMessage(messageData) {
    let message = this.createMessage(messageData)
    this.messageList.insertBefore(message, this.messageList.firstElementChild)
  }

  appendNextPage(messages) {
    messages.forEach((message) => {
      this.appendMessage(message)
      this.messages.push(message)
    })
  }

  dateFormat(dateString) {
    let timestamp = Date.parse(dateString)
    let date = new Date(timestamp)
    let now = new Date()
    let delta = Math.abs(date - now)
    let formatedDate = ""

    let minutes = date.getMinutes().toString()
    minutes = minutes.length < 2 ? `0${minutes}` : minutes

    if (date.toDateString() === now.toDateString()) {
      formatedDate = `${date.getHours()}:${minutes}`
    } else if (delta < WEEK) {
      formatedDate = `${
        getWeekdays()[date.getDay()]
      }, ${date.getHours()}:${minutes}`
    } else {
      formatedDate = `${date.getDate()}.${
        date.getMonth() + 1
      }.${date.getFullYear()}, ${date.getHours()}:${minutes}`
    }

    return formatedDate
  }

  urlEncode(name, value) {
    return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  }

  setTextareaHeight() {
    this.textarea.style.height = ""
    this.textarea.style.height = `${this.textarea.scrollHeight}px`
  }

  setNextPage(nextPage) {
    if (this.currentPage !== nextPage) {
      this.nextPage = nextPage
    } else {
      this.nextPage = null
    }

    if (this.nextPage !== null) {
      this.nextPageButton.setAttribute("aria-hidden", false)

      // move button to the end of the list
      this.messageList.appendChild(this.nextPageButton.parentElement)
    } else {
      this.nextPageButton.setAttribute("aria-hidden", true)
    }
  }

  scrollToBottom(forceToScroll = false) {
    if (
      forceToScroll ||
      this.scrollingArea.scrollTop >
        this.scrollingArea.scrollHeight - this.scrollingArea.clientHeight * 1.5
    ) {
      this.scrollingArea.scrollTop = this.scrollingArea.scrollHeight
    }
  }

  setFocus() {
    if (MEDIUM_UP.matches) {
      this.textarea.focus()
    }
  }
}

class ConversationForm {
  constructor() {
    this.form = $("[data-add-form]")
    this.button = $("[data-add-form-toggle]")
    this.csrftoken = $("input[name=csrfmiddlewaretoken]", this.form)
    this.error = $("[data-add-form-error]", this.form)

    this.userList = $("[data-add-form-user-list]", this.form)

    this.isExpanded = false
    this.form.setAttribute("aria-hidden", true)

    this.addListeners()
  }

  addListeners() {
    this.button.addEventListener("click", (e) => {
      e.preventDefault()
      this.toggle()
    })

    this.form.addEventListener("submit", (e) => {
      e.preventDefault()
      this.submitForm(e.target)
    })
  }

  submitForm(form) {
    let receiver = $("select[name=receiver]", this.form)
    let text = $("textarea[name=text]", this.form)

    $.fetch(form.action, {
      method: form.method,
      data: `${this.urlEncode(receiver.name, receiver.value)}&${this.urlEncode(
        text.name,
        text.value,
      )}`,
      headers: {
        "X-CSRFToken": this.csrftoken.value,
      },
    })
      .then(() => {
        location.reload()
      })
      .catch(() => {
        this.error.setAttribute("aria-hidden", false)
      })
  }

  toggle(focusOnTextarea) {
    if (this.isExpanded) {
      this.form.setAttribute("aria-hidden", true)
      this.button.setAttribute("aria-expanded", false)
      this.isExpanded = false
    } else {
      this.form.setAttribute("aria-hidden", false)
      this.button.setAttribute("aria-expanded", true)
      this.isExpanded = true

      if (focusOnTextarea) {
        $("textarea", this.form).focus()
      }
    }
  }

  selectUser(userId) {
    let user = $(`[value="${userId}"]`, this.userList)
    let selected = false

    if (user !== null) {
      selected = true
      user.selected = true
    }

    return selected
  }

  urlEncode(name, value) {
    return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  }
}

// for browsers that don't support responseType = 'json'
function parseJSON(xhr) {
  let response = null

  if (typeof xhr.response === "string" && xhr.responseType !== "json") {
    response = JSON.parse(xhr.response)
  } else {
    response = xhr.response
  }

  return response
}

export default Messenger
