/* global $: false */

class Subscription {
  constructor(csrf) {
    this.csrf = csrf
  }

  subscribe(actionURL, onSuccess, onError) {
    this.sendForm("POST", actionURL, onSuccess, onError)
  }

  unsubscribe(actionURL, onSuccess, onError) {
    this.sendForm("DELETE", actionURL, onSuccess, onError)
  }

  sendForm(method, actionURL, onSuccess = () => {}, onError = () => {}) {
    $.fetch(actionURL, {
      method,
      headers: { "X-CSRFToken": this.csrf },
    })
      .then(() => {
        onSuccess()
      })
      .catch(() => {
        onError()
      })
  }
}

export default Subscription
