import "./main.scss"

import "blissfuljs"
import { Gallery } from "bliss-gallery"

import Navigation from "./js/navigation"
import PostForm from "./js/post_form.js"
import SubscriptionList from "./js/subscription_list.js"
import Messenger from "./js/messenger.js"
import FriendList from "./js/friend_list.js"
import FriendRequestList from "./js/friend_request_list.js"
import Topic from "./js/topic.js"
import AvatarList from "./js/avatar_list.js"
import Accordion from "./js/accordion.js"
import SortForm from "./js/sort_form.js"
import ScrollTop from "./js/scroll_top.js"
import PreventDouble from "./js/preventDouble"

const { $, $$ } = window

$.ready().then(() => {
  new Navigation()

  $$("[data-post-form]").forEach((el) => {
    new PostForm(el)
  })

  $$("[data-subscription-list]").forEach((el) => {
    new SubscriptionList(el)
  })

  $$("[data-messenger]").forEach((el) => {
    new Messenger(el)
  })

  $$("[data-friend-list]").forEach((el) => {
    new FriendList(el)
  })

  $$("[data-friend-requests]").forEach((el) => {
    new FriendRequestList(el)
  })

  $$("[data-topic]").forEach((el) => {
    new Topic(el)
  })

  $$("[data-avatar-list]").forEach((el) => {
    new AvatarList(el)
  })

  $$("[data-accordion]").forEach((el) => {
    new Accordion(el)
  })

  $$("[data-sort-form]").forEach((el) => {
    new SortForm(el)
  })

  $$("[data-scroll-top]").forEach((el) => {
    new ScrollTop(el)
  })

  const galleries = $$(".gallery")

  galleries.forEach((el) => {
    new Gallery(el, {
      interval: 5000,
      autoPlay: true,
    })
  })

  $$("[data-prevent-double]").forEach((el) => {
    new PreventDouble(el)
  })
})
