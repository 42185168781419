/* global $: false */

class FriendRequestList {
  constructor(el) {
    this.requestList = el
    this.csrftoken = $("input[name=csrfmiddlewaretoken]", this.requestList)
    this.errorMessage = $("[data-friend-requests-error]", this.requestList)

    this.addListener()
  }

  addListener() {
    this.requestList._.delegate("submit", "form", (e) => {
      e.preventDefault()
      this.submitForm(e.target)
    })
  }

  submitForm(form) {
    let stateField = $("input[name=state]", form)

    $.fetch(form.action, {
      method: form.method,
      data: this.urlEncode(stateField.name, stateField.value),
      headers: {
        "X-CSRFToken": this.csrftoken.value,
      },
    })
      .then(() => {
        location.reload()
      })
      .catch(() => {
        this.errorMessage.setAttribute("aria-hidden", false)
      })
  }

  urlEncode(name, value) {
    return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  }
}

export default FriendRequestList
