/* global $: false */

class HitTopic {
  constructor(el) {
    this.el = el
    this.csrftoken = $("input[name=csrfmiddlewaretoken]", this.el)

    this.hitUrl = this.el.dataset.hitTopic
    this.sessionStorageKey = `topic-hit-${this.el.dataset.topicId}`

    this.hasHit = false

    this.hasHit = !!sessionStorage.getItem(this.sessionStorageKey)

    if (!this.hasHit) {
      this.send()
    }
  }

  send() {
    $.fetch(this.hitUrl, {
      method: "POST",
      headers: {
        "X-CSRFToken": this.csrftoken.value,
      },
    })
      .then(() => {
        sessionStorage.setItem(this.sessionStorageKey, true)
      })
      .catch((error) => {
        if (error.status === 480) {
          setTimeout(() => this.send(), 2000)
        }
      })
  }
}

export default HitTopic
