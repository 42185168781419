/* global $$: false */
import { Gallery } from "bliss-gallery"

const MEDIUM_UP = window.matchMedia("screen and (min-width: 640px)")
window.MEDIUM_UP = MEDIUM_UP

class AvatarList {
  constructor(el) {
    this.el = el
    this.gallery = null

    this.radioInputs = $$(".avatars__input", this.el)

    this.isActive = false

    this.defineListeners()
    this.addListeners()

    if (!MEDIUM_UP.matches) {
      this.activateGallery()
    }
  }

  defineListeners() {
    this._radioChangeListener = (e) => this.radioChangeListener(e)
  }

  /*
   * Add breakpoint listeners
   */
  addListeners() {
    MEDIUM_UP.addListener((e) => {
      if (e.matches) {
        this.deactivateGallery()
      } else {
        this.activateGallery()
      }
    })
  }

  /*
   * Listeners which will be added when the gallery is active
   */
  addActiveListeners() {
    // Accessibility
    // Get index of the active radio button and reveal its slide
    this.radioInputs.forEach((input) => {
      input.addEventListener("change", this._radioChangeListener)
    })
  }

  /*
   * Remove all the listeners which should be added if the gallery is active
   */
  removeActiveListeners() {
    this.radioInputs.forEach((input) => {
      input.removeEventListener("change", this._radioChangeListener)
    })
  }

  /*
   * Listens for a radio button to change and reveals it inside the gallery
   * Accessibility: Users can navigation with the keyboard through the radio button/avatar list
   * and the gallery will always show the selected avatar.
   */
  radioChangeListener(e) {
    let index = this.getAvatarIndex(e.currentTarget)
    if (index >= 0) {
      this.gallery.reveal(index)
    }
  }

  /*
   * Activate the gallery and reveals the currently selected avatar
   */
  activateGallery() {
    if (!this.isActive) {
      let activeAvatar = this.getActiveAvatar()

      this.gallery = new Gallery(this.el, {
        createThumbs: false,
        autoPlay: false,
      })

      if (activeAvatar !== null) {
        let index = this.getAvatarIndex(activeAvatar)

        if (index >= 0) {
          this.gallery.reveal(index)
        }
      }

      this.addActiveListeners()
      this.isActive = true
    }
  }

  /*
   * Deactivate the gallery.
   * Creates (clones) a new main element to make sure that event listeners
   * from libraries that you can't control won't still stick to the element.
   * Removes also all the added style attributes.
   */
  deactivateGallery() {
    if (this.isActive) {
      let elClone = this.el.cloneNode()

      while (this.el.firstChild) {
        if (this.el.lastChild.style !== undefined) {
          this.el.lastChild.removeAttribute("style")
        }
        elClone.appendChild(this.el.lastChild)
      }

      this.el.removeAttribute("style")
      this.el.parentNode.replaceChild(elClone, this.el)
      this.el = elClone

      this.removeActiveListeners()

      this.isActive = false
      this.gallery = null
    }
  }

  /*
   * Returns the currently active avatar / radio input field
   */
  getActiveAvatar() {
    let activeRadio = null

    this.radioInputs.forEach((input) => {
      if (input.checked) {
        activeRadio = input
      }
    })

    return activeRadio
  }

  /*
   * Returns the index of a given avatar.
   * The index can be used to control the gallery.
   */
  getAvatarIndex(avatar) {
    let slide = avatar.parentElement
    let slider = slide.parentElement
    let slides = Array.prototype.slice.call(slider.children)
    let index = slides.indexOf(slide)

    return index
  }
}

export default AvatarList
