class Accordion {
  constructor(el) {
    this.el = el
    this.addListener()
  }

  addListener() {
    this.el._.delegate("click", "[data-accordion-toggle]", (e) => {
      e.preventDefault()
      this.toggle(e.target)
    })
  }

  toggle(item) {
    let isExpanded = item.getAttribute("aria-expanded")

    if (isExpanded === "true") {
      isExpanded = true
    } else {
      isExpanded = false
    }

    item.setAttribute("aria-expanded", !isExpanded)
  }
}

export default Accordion
