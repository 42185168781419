/* global $:false, CKEDITOR:false */

class PostForm {
  constructor(el) {
    this.el = el
    this.link = $("[data-post-form-link]", this.el)
    this.wrapper = $("[data-post-form-wrapper]", this.el)

    this.sendUrl = this.el.dataset.postForm

    this.formIsShown = false
    this.isInitialised = false

    this.addListeners()
  }

  init() {
    this.form = $("form", this.wrapper)
    this.submitButton = $("[data-post-form-submit]", this.form)
    this.csrfmiddlewaretoken = $("input[name=csrfmiddlewaretoken]", this.form)
    this.messageField = $("textarea", this.form)
    this.error = $(".error", this.form)

    this.updateId()

    this.initialiseCKEditor(this.messageField)

    this.isInitialised = true
  }

  // The markup of the form gets generated by the backend. It uses the same form everytime.
  // This method sets a unique id to the textarea and the label to prevent confussion and conflicts.
  updateId() {
    let newId = `${this.messageField.id}-${this.form.dataset.newId}`

    $(`label[for=${this.messageField.id}]`, this.form).htmlFor = newId
    this.messageField.id = newId
  }

  addListeners() {
    // Toggle the from and initialise it, if it isn't yet.
    this.link.addEventListener("click", (e) => {
      e.preventDefault()

      if (!this.isInitialised) {
        this.init()
      }

      this.toggleForm()
    })

    //  Send the form
    this.wrapper._.delegate("click", "form button", (e) => {
      e.preventDefault()
      this.sendForm()
    })
  }

  /*
   *   Show and hide the form.
   */
  toggleForm() {
    if (this.formIsShown) {
      this.hideForm()
    } else {
      this.showForm()
    }
  }

  hideForm() {
    this.wrapper.classList.add("hide")
    this.formIsShown = false
    this.setAriaAttributes()
  }

  showForm() {
    this.wrapper.classList.remove("hide")
    this.formIsShown = true
    this.setAriaAttributes()

    // Set focus to the editor
    CKEDITOR.instances[this.messageField.id].focus()
  }

  /*
   *   Send Form Data to the according URL.
   *   The server will return a document if an error occurs.
   *   If everything is fine the server will return a redirect URL (TODO).
   */
  sendForm() {
    this.showLoadingSpinner()

    $.fetch(this.sendUrl, {
      method: this.form.method,
      responseType: "document",
      data: this.urlEncodeFormData(),
      headers: {
        "X-CSRFToken": this.csrfmiddlewaretoken.value,
      },
    })
      .then((data) => {
        let newPostForm = $("#js-post-form", data.response)

        if (newPostForm !== null) {
          this.replaceForm(newPostForm)
        } else {
          this.reloadPage()
        }
      })
      .catch(() => {
        this.showError()
      })
  }

  /*
   *   Encode the form data so that it can be sent with an XMLHttpRequest
   */
  urlEncodeFormData() {
    let messageData = CKEDITOR.instances[this.messageField.id].getData()

    return `${encodeURIComponent(this.messageField.name)}=${encodeURIComponent(
      messageData,
    )}`
  }

  reloadPage() {
    // TODO: Replace `location` with the URL that will be returned by the server.
    location.reload()
  }

  /*
   *   Replace the current form with the form that is returned by the server.
   *   Contains errormessages as well.
   */
  replaceForm(postForm) {
    this.form.remove()
    this.wrapper.appendChild(postForm)
    this.init()
    CKEDITOR.instances[this.messageField.id].focus()
  }

  /*
   *   shows general and hard coded error message
   */
  showError() {
    this.hideLoadingSpinner()
    this.error.classList.remove("hide")
    this.error.setAttribute("aria-hidden", false)
  }

  showLoadingSpinner() {
    this.form.classList.add("form--loading")
    this.submitButton.disabled = true
    this.submitButton.innerText = "Bitte warten..."
  }

  hideLoadingSpinner() {
    this.form.classList.remove("form--loading")
    this.submitButton.disabled = false
    this.submitButton.innerText = "Abschicken"
  }

  /*
   *   Initialisation of the CKEditor.
   */
  initialiseCKEditor(textarea) {
    if (
      textarea.getAttribute("data-processed") == "0" &&
      textarea.id.indexOf("__prefix__") == -1
    ) {
      textarea.setAttribute("data-processed", "1")

      let ext = JSON.parse(
        textarea.getAttribute("data-external-plugin-resources"),
      )

      for (let j = 0; j < ext.length; ++j) {
        CKEDITOR.plugins.addExternal(ext[j][0], ext[j][1], ext[j][2])
      }

      CKEDITOR.replace(
        textarea,
        JSON.parse(textarea.getAttribute("data-config")),
      )

      this.ckeditor = CKEDITOR.instances[textarea.id]
    }
  }

  /*
   *   Set Aria attributes
   */
  setAriaAttributes() {
    this.link.setAttribute("aria-expanded", this.formIsShown)
    this.wrapper.setAttribute("aria-hidden", !this.formIsShown)
  }
}

export default PostForm
