/* global $: false, $$: false */

class FriendList {
  constructor(el) {
    this.el = el

    this.friendList = $$("[data-friends]", this.el)
    // this.friendRequestsList = $('[data-friend-requests]', this.el)

    this.csrftoken = $("input[name=csrfmiddlewaretoken]", this.el)
    this.pageWrapper = $(".page-wrapper")

    this.modal = $("[data-modal]")
    this.nicknamePlaceholder = $("[data-modal-nickname]", this.modal)
    this.buttons = $$("button", this.modal)
    this.modalFocusPosition = 0
    this.errorMessage = $("[data-modal-error]")

    this.modalIsShown = false
    this.formToSubmit = null

    this.addListener()
    this.init()
  }

  init() {
    document.body.appendChild(this.modal)
  }

  addListener() {
    this.friendList._.delegate("submit", "form", (e) => {
      e.preventDefault()
      this.formToSubmit = e.target
      let isFriendRequest = this.formToSubmit.dataset.request || false
      this.toggleModal(isFriendRequest)
    })

    this.modal._.delegate("click", "button", (e) => {
      if (e.target.dataset.modalSend === "") {
        this.toggleModal()
        this.submitForm()
      } else if (e.target.dataset.modalCancel === "") {
        this.toggleModal()
        this.formToSubmit = null
      }
    })

    this.modal._.delegate("keydown", "button", (e) => {
      if (e.keyCode === 9) {
        if (e.shiftKey) {
          if (this.modalFocusPosition === 0) {
            this.modalFocusPosition = this.buttons.length - 1
          } else {
            this.modalFocusPosition -= 1
          }
        } else if (this.modalFocusPosition === this.buttons.length - 1) {
          this.modalFocusPosition = 0
        } else {
          this.modalFocusPosition += 1
        }
        e.preventDefault()
        this.buttons[this.modalFocusPosition].focus()
      }
    })

    this.modal.addEventListener("keydown", (e) => {
      if (e.keyCode === 27 && this.modalIsShown) {
        this.toggleModal()
      }
    })
  }

  toggleModal(isFriendRequest) {
    if (!this.modalIsShown) {
      if (isFriendRequest) {
        this.modal.classList.add("friend-request")
      }

      this.nicknamePlaceholder.innerHTML =
        this.formToSubmit.dataset.formNickname
      this.modal.setAttribute("aria-hidden", false)
      this.pageWrapper.setAttribute("aria-hidden", true)
      this.modalIsShown = true

      this.buttons[0].focus()
    } else {
      this.modal.setAttribute("aria-hidden", true)
      this.pageWrapper.setAttribute("aria-hidden", false)
      this.modalFocusPosition = 0
      this.nicknamePlaceholder.innerHTML = ""
      this.modalIsShown = false

      if (this.modal.classList.contains("friend-request")) {
        this.modal.classList.remove("friend-request")
      }

      $("button", this.formToSubmit).focus()
    }
  }

  submitForm() {
    let form = this.formToSubmit
    let stateField = $("input[name=state]", form)

    $.fetch(form.action, {
      method: form.method,
      data: this.urlEncode(stateField.name, stateField.value),
      headers: {
        "X-CSRFToken": this.csrftoken.value,
      },
    })
      .then(() => {
        location.reload()
      })
      .catch(() => {
        this.errorMessage.setAttribute("aria-hidden", false)
      })
  }

  urlEncode(name, value) {
    return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  }
}

export default FriendList
