import _throttle from "lodash/throttle"

class ScrollTop {
  constructor(el) {
    this.el = el
    this.isShown = false
    this.threshold = 450

    this.init()
  }

  init() {
    if (this.checkScrollPosition()) {
      this.toggle()
    }

    this.addListener()
  }

  addListener() {
    window.addEventListener(
      "scroll",
      _throttle(() => {
        if (this.checkScrollPosition()) {
          this.toggle()
        }
      }, 300),
    )
  }

  checkScrollPosition() {
    let change = false

    if (window.pageYOffset > this.threshold && !this.isShown) {
      /* scroll position is higher than the threshold and the button is still hidden */
      change = true
    } else if (window.pageYOffset <= this.threshold && this.isShown) {
      /* scroll position is lower than the threshold and the button is still shown */
      change = true
    }

    return change
  }

  toggle() {
    if (this.isShown) {
      this.el.classList.remove("show")
      this.isShown = false
    } else {
      this.el.classList.add("show")
      this.isShown = true
    }
  }
}

export default ScrollTop
