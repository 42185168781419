/* global $: false, $$: false */
import HitTopic from "./hit_topic.js"
import SubscriptionForm from "./subscription_form.js"

class Topic {
  constructor(el) {
    this.el = el
    this.userInfoUrl = this.el.dataset.userInfo
    this.id = parseInt(this.el.dataset.topicId, 10)

    this.friends = null
    this.sent_friend_requests = null
    this.received_friend_requests = null
    this.subscriptions = null

    this.requestFormWrappers = $$("[data-send-request]", this.el)
    this.requestForms = []

    this.subscriptionForm = $("[data-subscribe]", this.el)

    this.fetchUserInfo()

    new HitTopic(this.el)

    $$("[data-post-rating]", this.el).forEach((ratingForm) => {
      new PostRating(ratingForm)
    })
  }

  fetchUserInfo() {
    $.fetch(this.userInfoUrl, {
      method: "GET",
      responseTyp: "json",
    })
      .then((xhr) => {
        let response = this.parseJSON(xhr)

        this.friends = response.friends
        this.sent_friend_requests = response.sent_friend_requests
        this.received_friend_requests = response.received_friend_requests

        this.initSubscriptionForm(response.subscriptions)
        this.checkFriends()
      })
      .catch(() => {
        this.friends = []
        this.sent_friend_requests = []
        this.received_friend_requests = []
        this.subscriptions = null
      })
  }

  // for browsers that don't support responseType = 'json'
  parseJSON(xhr) {
    let response = null

    if (typeof xhr.response === "string" && xhr.responseType !== "json") {
      response = JSON.parse(xhr.response)
    } else {
      response = xhr.response
    }

    return response
  }

  // set the initial value of the subscribe checkbox
  initSubscriptionForm(subscriptions) {
    if (this.subscriptionForm !== null) {
      let checkbox = $('input[type="checkbox"]', this.subscriptionForm)

      if (subscriptions.indexOf(this.id) >= 0) {
        checkbox.checked = true
      }

      new SubscriptionForm(this.subscriptionForm)
    }
  }

  /* Check if the author is already a friend or if they've already received or sent a friend request */
  /* The css class will reveal either a label, the form to send a request or a link to the messenger */
  checkFriends() {
    this.requestFormWrappers.forEach((requestWraper) => {
      let id = parseInt(requestWraper.dataset.sendRequest, 10)
      let className = null

      this.friends.forEach((user) => {
        if (user.id === id) {
          className = "is-friend"
        }
      })

      if (className === null) {
        this.sent_friend_requests.forEach((user) => {
          if (user.id === id) {
            className = "sent-request"
          }
        })
      }

      if (className === null) {
        this.received_friend_requests.forEach((user) => {
          if (user.id === id) {
            className = "received-request"
          }
        })
      }

      if (className === null) {
        className = "not-friend"
        this.requestForms.push(new RequestForm($("form", requestWraper)))
      }

      requestWraper.classList.add(className)
    })
  }
}

class RequestForm {
  constructor(el) {
    this.form = el
    this.receiverField = $("input[name=receiver]", this.form)
    this.csrftoken = $("input[name=csrfmiddlewaretoken]", this.form)
    this.errorMessage = $("[data-request-error]", this.form)

    this.addListeners()
  }

  addListeners() {
    this.form.addEventListener("submit", (e) => {
      e.preventDefault()
      this.submitForm()
    })
  }

  submitForm() {
    $.fetch(this.form.action, {
      method: this.form.method,
      data: this.urlEncode(this.receiverField.name, this.receiverField.value),
      headers: {
        "X-CSRFToken": this.csrftoken.value,
      },
    })
      .then(() => {
        location.reload()
      })
      .catch((error) => {
        if (error.status === 400) {
          location.reload()
        } else {
          this.form.parentElement.classList.add("fail")
        }
      })
  }

  urlEncode(name, value) {
    return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  }
}

class PostRating {
  constructor(form) {
    this.form = form
    this.csrftoken = $("input[name=csrfmiddlewaretoken]", this.form)

    this.addListeners()
  }

  addListeners() {
    $.delegate(this.form, "change", "input[type=radio]", (e) => {
      this.submitRating(e.target)
    })
  }

  submitRating(ratingField) {
    $.fetch(this.form.action, {
      method: this.form.method,
      data: this.urlEncode(ratingField.name, ratingField.value),
      headers: {
        "X-CSRFToken": this.csrftoken.value,
      },
    })
  }

  urlEncode(name, value) {
    return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  }
}

export default Topic
